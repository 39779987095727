<template>
  <v-card class="ma-4">
    <v-toolbar :color="selectedItems.length ? 'grey darken-4' : '#335D6E'" dark
               elevation="1">

      <v-toolbar-title> {{
          selectedItems.length ? `Selected (${selectedItems.length})  rows` : title
        }}
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-text-field
          v-if="!selectedItems.length"
          v-model="search"
          append-icon="mdi-magnify"
          clearable
          filled
          hide-details
          label="Search about something"
          single-line
      ></v-text-field>

      <v-btn
          v-if="selectedItems.length"
          color="grey darken-2"
          dark
          @click.stop="restoreItems"
      >Restore
      </v-btn>

      <v-dialog v-if="!selectedItems.length" v-model="dialogAddItem" fullscreen
                hide-overlay
                transition="dialog-bottom-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="mx-11"
              color="#082d3c"
              dark
              v-bind="attrs"
              v-on="on"
          >New
          </v-btn>
        </template>

        <v-card v-if="dialogAddItem">
          <v-toolbar
              color="blue-grey"
              dark
          >
            <v-btn
                dark
                icon
                @click="dialogAddItem = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <template v-slot:extension>
              <v-tabs
                  v-model="tab"
                  center-active
                  centered

              >
                <v-tabs-slider color="yellow"></v-tabs-slider>

                <v-tab
                    v-for="tabItem in tabItems"
                    :key="tabItem"
                    @click="tab=tabItem"
                >
                  {{ tabItem }}
                </v-tab>
              </v-tabs>
            </template>
          </v-toolbar>


          <v-alert
              v-if="Object.keys(errors).length>0"
              dense
              text
              type="error"
          >
            <h4 class="subtitle">Correct the following errors:</h4>
            <ul>
              <li
                  v-for="(error, index) in errors"
                  :key="index"
              >{{ error.message || error }}
              </li>
            </ul>
          </v-alert>

          <v-card-text>
            <v-container>
              <v-tabs-items v-model="tab">
                <v-tab-item
                    v-for="tabItem in tabItems"
                    :key="tabItem"
                >

                  <v-form
                      v-if="tabItem===tabItems[0]"
                      ref="form"
                      v-model="valid"
                      class="mt-4"
                      lazy-validation>
                    <div class="d-flex flex-row-reverse mb-6 pa-2   grey darken-3 ">
                      <v-btn
                          :disabled="(!valid || buttonLoading)"
                          :loading="buttonLoading"
                          class="mr-2 px-3"
                          color="grey"
                          dark
                          @click="validate"
                      >Save
                      </v-btn>
                      <v-btn
                          class="mr-2 px-3"
                          color="grey"
                          dark
                          @click="clear"
                      >clear
                      </v-btn>
                    </div>

                    <v-row>

                      <v-col cols="12" lg="6" md="6" sm="12">
                        <v-autocomplete
                            v-model="user"
                            :items="users"
                            :rules="[v => !!v || 'Please select customer']"
                            hide-details
                            item-text="name"
                            item-value="id"
                            label="Customer Name"
                            outlined
                            required
                            return-object
                        ></v-autocomplete>
                      </v-col>

                      <v-col cols="12" lg="6" md="6" sm="12">
                        <v-select
                            v-model="plan"
                            :items="plans"
                            :rules="[v => !!v || 'Please select plan']"
                            hide-details
                            item-text="name"
                            item-value="id"
                            label="Plan Name"
                            outlined
                            required
                            return-object
                        ></v-select>
                      </v-col>

                      <v-col cols="12" lg="3" md="4" sm="6">
                        <v-text-field v-model="price" label="Plan price" min="0"
                                      outlined required
                                      type="number"></v-text-field>
                      </v-col>

                      <v-col cols="12" lg="3" md="4" sm="6">
                        <v-text-field v-model="discount" label="Discount price" min="0"
                                      outlined required
                                      type="number"></v-text-field>
                      </v-col>

                      <v-col cols="12" md="3" sm="6">
                        <v-text-field
                            v-model="startedAt"
                            :rules="[v => !!v || 'Please select start date']"
                            label="plan started at"
                            outlined
                            required
                            type="date"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="3" sm="6">
                        <v-text-field
                            v-model="expiredAt"
                            label="plan expired at"
                            outlined
                            type="date"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" lg="3" md="4" sm="6">
                        <v-select
                            v-model="status"
                            :items="listStatus"
                            hide-details
                            item-text="name"
                            item-value="id"
                            label="Plan Status"
                            outlined
                            required
                            return-object
                        ></v-select>
                      </v-col>

                    </v-row>
                  </v-form>

                  <PlanAppliance v-if="tabItem===tabItems[1]" :planUserID="id"/>

                </v-tab-item>
              </v-tabs-items>
            </v-container>
          </v-card-text>

        </v-card>
      </v-dialog>

      <v-btn
          v-else
          class="mx-11"
          color="grey darken-2"
          dark
          @click.stop="showDialogDelete"
      >Delete
      </v-btn>
      <v-btn
          v-if="selectedItems.length"
          icon
          @click="selectedItems = []"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-menu
          v-else
          :close-on-content-click="false"
          :nudge-width="250"
          offset-x
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              icon
              v-bind="attrs"
              v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-action>
                <v-switch
                    v-model="isShowDeleted"
                    color="indigo"
                ></v-switch>
              </v-list-item-action>
              <v-list-item-title class="ml-4">Show deleted data</v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-action>
                <v-switch
                    v-model="isForceDeleted"
                    color="indigo"
                ></v-switch>
              </v-list-item-action>
              <v-list-item-title class="ml-4">Delete data forever</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-toolbar>

    <v-card-text>
      <v-dialog
          v-model="dialogDelete"
          max-width="425"
          persistent
      >
        <v-card>
          <v-card-title>
            Delete group of data ({{ selectedItems.length ? selectedItems.length : 1 }}) rows
          </v-card-title>

          <v-card-text class=" ">
            Are you sure for continue deleting these data ?
          </v-card-text>

          <v-card-actions>
            <v-checkbox
                v-model="isForceDeletedRow"
                :disabled=disableChangeDelete
                :ripple=false
                class="ma-2 red--text "
                color="red"
                hide-details
            >
              <template v-slot:label>
                <div class="red--text body-2">
                  Delete data forever
                </div>
              </template>
            </v-checkbox>

            <v-spacer></v-spacer>

            <v-btn
                :disabled="buttonLoading"
                class="body-2"
                color="primary"
                text
                @click="hideDialogDelete()"
            >
              Reject
            </v-btn>

            <v-btn
                :disabled="buttonLoading"
                class="body-2"
                color="red darken-2"
                text
                @click="destroyItems"
            >
              Process
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
          v-if="!dialogDelete && !dialogAddItem"
          v-model="buttonLoading"
          hide-overlay
          persistent
          width="300"
      >
        <v-card
            color="indigo"
            dark
        >
          <v-card-text>
            <div class="py-4 body-1 white--text text-center">Waiting for process request... please wait</div>

            <v-progress-linear
                class="my-2"
                color="white"
                indeterminate
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-data-table
          v-model="selectedItems"
          :footer-props="{'items-per-page-options': [10, 20, 40, 80, 100]}"
          :headers="headers"
          :item-class="(item)=>item.deletedAt?'deletedRow':null"
          :items="desserts"
          :items-per-page="10"
          :loading="loading"
          :options.sync="options"
          :search="search"
          checkbox-color="'blue'"
          item-key='id'
          show-select
      >


        <template v-slot:header.data-table-select="{props,on}">
          <v-simple-checkbox :ripple=false color="primary" v-bind="props" v-on="on"></v-simple-checkbox>
        </template>
        <template v-slot:item.data-table-select="{isSelected,select}">
          <v-simple-checkbox :ripple=false :value="isSelected" color="primary"
                             @input="select($event)"></v-simple-checkbox>
        </template>
        <template v-slot:item.id="{ item }">
          <v-chip>{{ item.id }}</v-chip>
        </template>
        <template v-slot:item.deletedAt="{ item }">
          <v-icon
              v-if="item.deletedAt"
              class="mr-3"
              color="primary"
              small
              @click="restoreItems(item)"
          >mdi-restore
          </v-icon>


          <v-chip
              color="indigo"
              label
              outlined
              small
              @click="editItem(item)"
          >
            <v-icon small>
              mdi-pencil
            </v-icon>
          </v-chip>


          <v-chip
              :color="item.deletedAt ?'orange':'red accent-4'"
              class="ml-3"
              label
              outlined
              small
              @click="showDialogDelete(item)"
          >
            <v-icon small>
              {{ item.deletedAt ? 'mdi-delete-clock' : ' mdi-delete' }}
            </v-icon>
          </v-chip>

        </template>


        <template v-slot:item.status="{ item }">
          <v-chip :color="statusColor(item.status)" dark label>{{ checkStatus(item.status) }}</v-chip>
        </template>

        <template v-slot:item.userID="{ item }">
          <v-chip>{{ getUser(item.userID).name }}</v-chip>
        </template>

        <template v-slot:item.planServiceID="{ item }">
          <v-chip color="primary">{{ getPlan(item.planServiceID).name }}</v-chip>
        </template>

        <template v-slot:item.startedAt="{ item }">
          <v-chip color="primary" dark outlined>{{ getFormattedDate(new Date(item.startedAt)) }}</v-chip>
        </template>

      </v-data-table>
    </v-card-text>
  </v-card>
</template>


<script>
import networks from '../../services/networks';
import {roleCustomers, urlCreatePlanUser, urlPlanServices, urlPlanUsers, urlUpdatePlanUser, urlUsersBasedRole} from "@/config";
import moment from "moment/moment";
import PlanAppliance from "@/views/care/PlanAppliance.vue";

export default {
  components: {PlanAppliance},
  data: () => ({
    search: null,
    dialogDelete: false,
    disableChangeDelete: false,
    deleteItemIDs: [],
    selectedItems: [],
    valid: true,
    id: 0,
    user: null,
    users: [],
    plan: null,
    plans: [],
    price: 0,
    discount: 0,
    status: {id: -1},
    listStatus: [{id: 0, name: "Pending"}, {id: 1, name: "Active"}, {id: 2, name: "Canceled"}, {
      id: 3,
      name: "Completed"
    }],
    startedAt: null,
    expiredAt: null,
    errors: [],

    buttonLoader: 'buttonLoading',
    buttonLoading: false,
    dialogAddItem: false,

    title: "Plan Users",

    options: null,
    desserts: [],
    loading: true,
    headers: [
      {text: '#', value: 'id'},
      {text: 'Customer Name', value: 'userID', align: 'center'},
      {text: 'Plan Name', value: 'planServiceID', align: 'center'},
      {text: 'Plan startedAt', value: 'startedAt', align: 'center'},
      {text: 'Status', value: 'status', align: 'center'},
      {text: 'Actions', value: 'deletedAt', sortable: false, align: 'right'},
    ],
    isFormTitleEdit: false,
    tab: null,
    tabItems: ['Plan User', 'Appliances'],
  }),

  created() {
    this.getUsersFromApi();
    this.getPlanServicesFromApi();
    this.getPlanUsersFromApi();
  },


  computed: {
    formTitle() {
      return this.isFormTitleEdit ? 'Edit plan user' : 'Add new plan user'
    },
    isShowDeleted: {
      get() {
        return this.$store.getters.isShowDeleted;
      },
      set(val) {
        this.$store.state.isShowDeleted = val;
        this.getPlanUsersFromApi();
        return val;
      }

    },
    isForceDeleted: {
      get() {
        return this.$store.getters.isForceDeleted;
      },
      set(val) {
        this.isForceDeletedRow = val;
        this.$store.state.isForceDeleted = val;
        return val;
      }
    },
    isForceDeletedRow: {
      get() {
        return this.$store.getters.isForceDeleted;
      },
      set(val) {
        this.$store.state.isForceDeleted = val;
        return val;
      }
    },
  },

  watch: {
    dialogAddItem() {
      if (!this.dialogAddItem) {
        this.isFormTitleEdit = false;
        this.clear();
      }
    },
  },

  methods: {
    getPlanUsersFromApi() {
      this.loading = true;
      networks.fetchFromWeb(urlPlanUsers + this.isShowDeleted)
          .then(response => {

            this.desserts = response.data;
            this.loading = false;
          })
          .catch(error => {
            alert(error);
          });


    },

    getUsersFromApi() {
      this.loading = true;
      networks.fetchFromWeb(urlUsersBasedRole + roleCustomers)
          .then(response => {
            this.users = response.data;
            this.loading = false;
          })
          .catch(error => {
            alert(error);
          });


    },

    getPlanServicesFromApi() {
      this.loading = true;
      networks.fetchFromWeb(urlPlanServices)
          .then(response => {
            this.plans = response.data;
            this.loading = false;
          })
          .catch(error => {
            alert(error);
          });


    },


    validate() {

      if (this.$refs.form[0].validate()) {
        this.buttonLoading = true;
        this.errors = [];
        this.saveItem();
      }
    },

    saveItem() {
      let data = {
        planServiceID: this.plan.id,
        userID: this.user.id,
        price: this.price,
        discount: this.discount,
        startedAt: this.startedAt,
        expireAt: this.expiredAt,
        status: this.status.id,
      };


      if (this.isFormTitleEdit)
        networks.fetchFromWeb(urlUpdatePlanUser + this.id, 2, data)
            .then(response => {

              if (response.status === 200) {
                this.clear();
                this.dialogAddItem = false;
                alert("the data has been edited successfully");
                this.getPlanUsersFromApi();
              }


            })
            .catch(error => {
              this.errors = error.response.data.errors || error.response.data.error;
              this.buttonLoading = false;
            });
      else {

        networks.fetchFromWeb(urlCreatePlanUser, 1, data)
            .then(response => {

              if (response.status == 200) {
                this.clear();
                this.dialogAddItem = false;
                alert("the data has been added successfully");
                this.getPlanUsersFromApi();
              }


            })
            .catch(error => {
              this.errors = error.response.data.errors || error.response.data.error;
              this.buttonLoading = false;

            });
      }

    },

    clear() {
      if (this.$refs.form != undefined && this.$refs.form[0]) {
        this.$refs.form[0].resetValidation();
      }

      this.id = 0;
      this.plan = null;
      this.user = null;
      this.price = null;
      this.discount = null;
      this.startedAt = null;
      this.expiredAt = null;
      this.status = null;
      this.isActive = true;
      this.isForAddon = true;
      this.tab = this.tabItems[0];
      this.valid = true;
      this.errors = [];
      this.buttonLoading = false;
    },


    editItem(item) {
      this.clear();
      this.isFormTitleEdit = true;
      this.id = item.id;
      this.price = item.price;
      this.discount = item.discount;
      this.status = {id: item.status};
      this.user = {id: item.userID};
      this.plan = {id: item.planServiceID};
      this.startedAt = item.startedAt;
      this.expiredAt = item.expireAt;
      this.dialogAddItem = true;
    },

    statusColor(status) {
      switch (status) {
        case 1:
          return "green";
        case 2:
          return "red";
        case 3:
          return "grey";
        case 4:
          return "brown";
        case 5:
          return "purple";
        case 6:
          return "indigo";
        default:
          return "amber";
      }
    },

    checkStatus(val) {
      let parent = this.listStatus.filter(c => c.id == val);
      if (parent.length > 0)
        return parent[0].name;
      return "";
    },

    getUser(id) {
      return this.users.find(c => c.id == id);
    },

    getPlan(id) {
      return this.plans.find(c => c.id == id);
    },

    getFormattedDate(date) {
      return moment(date).format("YYYY-MM-DD")
    },

    showDialogDelete(item) {
      if (this.selectedItems.length < 1) {
        if (!item.deletedAt || (item.deletedAt && this.isForceDeleted)) {
          this.disableChangeDelete = item.deletedAt;
          this.dialogDelete = true;
          this.deleteItemIDs.push(item.id);
        }
      } else {
        this.dialogDelete = true;
      }

    },
    hideDialogDelete() {
      this.dialogDelete = false;
      this.disableChangeDelete = false;
      this.deleteItemIDs = [];
      this.selectedItems = [];
    },
    destroyItems() {

      this.buttonLoading = true;
      if (this.selectedItems.length > 0) {
        let currentSelected = [];

        if (this.isForceDeletedRow)
          currentSelected = this.selectedItems;
        else
          currentSelected = this.selectedItems.filter((e) => e.deletedAt === false);


        currentSelected.map((e) => this.deleteItemIDs.push(e.id));
      }


      if (this.deleteItemIDs.length < 1) {
        this.hideDialogDelete();
        this.buttonLoading = false
      } else {
        networks.fetchFromWeb(urlPlanUsers + this.deleteItemIDs + "/" + this.isForceDeletedRow, 3)
            .then(response => {

              if (response.status == 200) {
                this.hideDialogDelete();
                alert("Data has been deleted");
                this.getPlanUsersFromApi();
              }

            })
            .catch(() => {
              alert("Failed for process delete data");
              this.hideDialogDelete();
            }).finally(() => this.buttonLoading = false);
      }

    },

    restoreItems(item) {
      this.buttonLoading = true;
      if (this.selectedItems.length > 0) {
        let currentSelected = [];
        currentSelected = this.selectedItems.filter((e) => e.deletedAt === true);
        currentSelected.map((e) => this.deleteItemIDs.push(e.id));

      } else {
        this.deleteItemIDs.push(item.id);
      }

      if (this.deleteItemIDs.length < 1) {
        this.hideDialogDelete();
        this.buttonLoading = false;
      } else {

        networks.fetchFromWeb(urlPlanUsers + this.deleteItemIDs + "/" + this.isForceDeletedRow + "/true", 3)
            .then(response => {

              if (response.status == 200) {
                this.hideDialogDelete();
                this.getPlanUsersFromApi();
              }

            })
            .catch(() => {
              alert("Failed for process delete data");
              this.hideDialogDelete();
            }).finally(() => this.buttonLoading = false);

      }
    },
  },
}
</script>



