<template>
  <div class="mt-4">
    <v-alert
        v-if="Object.keys(errors).length>0"
        dense
        text
        type="error"
    >
      <h4 class="subtitle">Correct the following errors:</h4>
      <ul>
        <li
            v-for="(error, index) in errors"
            :key="index"
        >{{ error.message || error }}
        </li>
      </ul>
    </v-alert>
    <v-form
        ref="form"
        v-model="valid"
        :disabled="buttonLoading"
        lazy-validation

    >
      <v-row>


        <v-col v-if="isNewAppliance" cols="12" lg="3" md="6" sm="6">
          <v-text-field v-model="name" :rules="nameRules"
                        label="Name"
                        outlined required></v-text-field>

        </v-col>
        <v-col v-if="isNewAppliance" cols="12" lg="3" md="6" sm="6">
          <v-text-field v-model="model"
                        label="Model"
                        outlined required></v-text-field>

        </v-col>
        <v-col v-if="isNewAppliance" cols="12" lg="3" md="6" sm="6">
          <v-text-field v-model="type"
                        label="Type"
                        outlined required></v-text-field>

        </v-col>
        <v-col v-if="isNewAppliance" cols="12" lg="3" md="6" sm="6">
          <v-text-field v-model="location"
                        label="Location"
                        outlined required></v-text-field>

        </v-col>

        <v-col v-if="isNewAppliance" cols="12" lg="6" md="6" sm="6">
          <v-text-field v-model="additional"
                        label="Additional info"
                        outlined required></v-text-field>

        </v-col>

        <v-col v-if="isNewAppliance" cols="2">
          <v-text-field
              v-model="startedAt"
              :rules="[v => !!v || 'Please select warranty start date']"
              label="plan started at"
              outlined
              required
              type="date"
          ></v-text-field>
        </v-col>

        <v-col v-if="isNewAppliance" cols="2">
          <v-text-field
              v-model="endedAt"
              label="warranty expired at"
              outlined
              required
              type="date"
          ></v-text-field>
        </v-col>

        <v-col v-if="isNewAppliance" cols="2">
          <v-select
              v-model="applianceService"
              :items="applianceServices"
              :rules="[v => !!v || 'Please select from menu']"
              hide-details
              item-text="name"
              item-value="id"
              label="Appliance Service"
              outlined
              required
              return-object
          ></v-select>
        </v-col>

        <v-col cols="12">
          <v-btn
              :disabled=" buttonLoading"
              :loading="buttonLoading"
              color="green"
              dark
              filled
              @click="validate"
          >{{ !isNewAppliance ? 'New Appliance' : 'Save Appliance' }}
          </v-btn>
          <v-btn
              v-if="isNewAppliance"
              color="orange"
              class="ml-4"
              dark
              filled
              @click="isNewAppliance=false"
          >Back
          </v-btn>
        </v-col>

        <v-col cols="12">


          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Location</th>
                <th>Type</th>
                <th>Model</th>
                <th>w. startedAt</th>
                <th>w. expiredAt</th>
                <th>Actions</th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(item,index) in appliances"
                  :key="item.id"
              >
                <td>
                  <v-chip>{{ index + 1 }}</v-chip>
                </td>
                <td>{{ item.name }}</td>
                <td>{{ item.location }}</td>
                <td>{{ item.type }}</td>
                <td>{{ item.model }}</td>
                <td>{{ convertTimeToDate(item.warrantyStartedAt) }}</td>
                <td>{{ convertTimeToDate(item.warrantyEndedAt) }}</td>
                <td>
                  <v-btn
                      class="ma-2"
                      color="blue"
                      dark
                      outlined
                      @click="editItem(item)"
                  >
                    Edit
                    <v-icon
                        dark
                        right
                    >
                      mdi-checkbox-marked-circle
                    </v-icon>
                  </v-btn>
                  <v-btn
                      color="red"
                      dark
                      outlined
                      @click="showDeleteDialog(item)"
                  >

                    Delete
                    <v-icon right>
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </td>

              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-form>
    <v-dialog v-if="showDialogDelete" v-model="showDialogDelete"
              max-width="290"
              persistent
    >

      <v-card>
        <v-card-title class="text-h5">
          Delete Data
        </v-card-title>
        <v-card-text>Do you want to continue process this operation ?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="red darken-1"
              text
              @click="processDeleteOP"
          >
            Delete
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="showDialogDelete = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import networks from '../../services/networks';
import {urlAppliances, urlCreateAppliance, urlUpdateAppliance,} from "@/config";

export default {
  props: {
    planUserID: Number,
  },
  data: () => ({
    name: null,
    location: null,
    type: null,
    model: null,
    additional: null,
    startedAt: null,
    endedAt: null,
    applianceService: null,
    applianceServices: [{id: 0, name: 'No'}, {id: 1, name: 'Yes'}, {id: 2, name: 'N/A'}],
    appliances: [],
    appliance: null,
    deleteRowID: 0,
    showDialogDelete: false,

    nameRules: [
      v => !!v || 'name is required',
      v => (v && v.length <= 150) || 'name must be less than 150 characters',
    ],
    errors: [],
    buttonLoader: 'buttonLoading',
    buttonLoading: false,
    loading: true,
    valid: true,
    isNewAppliance: false,
  }),

  created() {
    this.getAppliancesFromApi();
  },

  methods: {
    getAppliancesFromApi() {
      this.loading = true;
      networks.fetchFromWeb(urlAppliances + this.planUserID)
          .then(response => {
            this.appliances = response.data;
            this.loading = false;
          })
          .catch(error => {
            alert(error);
          });


    },

    convertTimeToDate(timeStamp, isFullDate = false) {
      const date = new Date(timeStamp);
      if (!isFullDate) {
        return date.toLocaleDateString('en-US', {year: 'numeric', month: 'long', day: 'numeric'});
      } else {
        return date.toLocaleDateString('en-US', {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        }) + "  " + date.toLocaleTimeString('en-US');
      }
    },

    showDeleteDialog(item) {
      this.deleteRowID = item.id;
      this.showDialogDelete = true;
    },

    validate() {
      if (!this.isNewAppliance) {
        this.isNewAppliance = true;

      } else if (this.$refs.form.validate()) {
        this.buttonLoading = true;
        this.errors = [];
        this.saveItem();
      }

    },

    saveItem() {
      let data = {
        name: this.name,
        applianceService: this.applianceService.id,
        location: this.location,
        type: this.type,
        model: this.model,
        additional: this.additional,
        startedAt: this.startedAt,
        endedAt: this.endedAt,
      };


      if (this.id > 0) {
        networks.fetchFromWeb(urlUpdateAppliance + this.id, 2, data)
            .then(response => {

              if (response.status == 200) {
                this.clear();
                this.isNewAppliance = false;
                alert("the data has been edited successfully");
                this.getAppliancesFromApi();
              }


            })
            .catch(error => {
              this.errors = error.response.data.errors || error.response.data.error;
              this.buttonLoading = false;
            });
      } else {
        data['planUserID'] = this.planUserID;

        networks.fetchFromWeb(urlCreateAppliance, 1, data)
            .then(response => {

              if (response.status == 200) {
                this.clear();
                this.isNewAppliance = false;
                alert("the data has been added successfully");
                this.getAppliancesFromApi();
              }


            })
            .catch(error => {
              this.errors = error.response.data.errors || error.response.data.error;
              this.buttonLoading = false;

            });
      }

    },

    clear() {
      if (this.$refs.form)
        this.$refs.form.resetValidation();

      this.name = null;
      this.id = 0;
      this.applianceService = null;
      this.location = null;
      this.type = null;
      this.model = null;
      this.additional = null;
      this.startedAt = null;
      this.endedAt = null;
      this.appliance = null;
      this.deleteRowID = 0;
      this.showDialogDelete = false;

      this.valid = true;
      this.errors = [];
      this.buttonLoading = false;
    },


    editItem(item) {
      this.clear();
      this.isNewAppliance = true;
      this.id = item.id;
      this.name = item.name;
      this.applianceService = {id: item.applianceService};
      this.type = item.type;
      this.model = item.model;
      this.location = item.location;
      this.additional = item.additional;
      this.startedAt = item.warrantyStartedAt;
      this.endedAt = item.warrantyEndedAt;
      this.itemBoiler = {id: item.parentID};
      this.dialogAddItem = true;
    },

    processDeleteOP() {


      this.showDialogDelete = false;

      networks.fetchFromWeb(urlAppliances + this.deleteRowID, 3)
          .then(response => {
            if (response.status === 200) {
              alert("Data has been deleted");
              this.getAppliancesFromApi();

            }
          })
          .catch(() => {
            alert("Failed for process delete data");
          });


    },
  }
}
</script>



